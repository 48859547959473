import React, { Component } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group"
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap"

import "../../styles/resources.css"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faChevronCircleRight,faDownload,faFile } from '@fortawesome/free-solid-svg-icons'

import logotipos from "../../pdfs/resources/logos.pdf"
import texturascomp from "../../pdfs/resources/texturascomplementarias.pdf"

const type = [
  {
    title: "01",
    text: "Logotipos",
    src: logotipos
  },
  {
    title: "02",
    text: "Texturas complementarias",
    src: texturascomp
  }
]
export default class Guides extends Component{
  constructor(props) {
  super(props)
  this.state =   {
      title:"01",
      text: "Manual de marca",
      src: logotipos
    }
}
setType = (title,text, src) => {
  this.setState({
    selType: title,
    text,
    src,
  })
}
render() {
    const { selType, src: srcG, text:textG} = this.state
  return(
    <Container style={{position:"relative"}} className="bg-resources" id="resources" fluid>
      <div className="innerbg" style={{background:`#f56a06`}}/>
      <Container className="">
        <Row className="" >
          <Col sm={12} md={6} className="" >
            <p className="text-resources text-white ml-5 mt-5"> RECURSOS </p>
            <Nav className="mt-5 font-weight-bolder text-white text-left" vertical>
              {type.map(({ icon, title, text,src }, i) => (
                <NavItem onClick={() => this.setType(title,text, src)}
                      onMouseEnter={() => this.setType(title,text, src)}
                      className=""
                      key={i}>

                      <NavLink tag="span" active={selType === text} className="mt-3">
                        {" "}
                        <h3 className="text_hovers">
                          <FontAwesomeIcon size="1x" icon={faChevronCircleRight} />
                         &nbsp;
                        {title} &nbsp;{text}
                      </h3>
                      <Row className="ml-4">
                        <a href={srcG} target="_blank" rel="noopener noreferrer" className="resources_dwnld">
                        <FontAwesomeIcon size="2x" icon={faFile}/>
                        </a>
                        <a href={srcG} download className="resources_dwnld">
                        <FontAwesomeIcon size="2x" icon={faDownload}/>
                        </a>
                      </Row>
                      </NavLink>
                </NavItem>))}
            </Nav>
          </Col>
          <Col sm={12} md={6} className="dispi">
            <TransitionGroup key={srcG} appear={true} exit={true}>
              <CSSTransition timeout={300} className="mt-5 ml-5">
                <div>
                  <p className="ml-5 mt-5 text-resources text-purple">
                    {selType}
                  </p>
                  <h3 className="ml-5 font-weight-bold">{textG}</h3>
                  <a href={srcG} download className="download_resources_iconcol d-flex flex-row-reverse mb-5">
                    <FontAwesomeIcon size="2x" icon={faDownload} />
                  </a>
                  <embed
                    src={srcG}
                    type="application/pdf"
                    width="100%"
                    height="800"
                  />
                  <br />
                </div>
              </CSSTransition>
            </TransitionGroup>
          </Col>
        </Row>
      </Container>
    </Container>
  )
  }
}
