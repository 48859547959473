import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {Guides, Home, Resources, ContactUs} from "../components/sections"

const IndexPage = () => (
  <Layout>
    <SEO title="Madea" />
    <div id="home"></div>
    <Home/>

    <div id="guias"></div>
    <Guides />

    <div id="resources"></div>
    <Resources/>

    <div id="contact"></div>
    <ContactUs/>
  </Layout>
)

export default IndexPage
