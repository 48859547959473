import React from "react";
import { Container,Row, Button } from "reactstrap"

import "../../styles/home.css"
import img_logo from "../../images/logo_pag_cliente.svg"
import arrow from "../../images/arrow.svg"



function Home(){
  return(
    <Container className="bg-home d-flex flex-column justify-content-center" id="home">
      <Row className="justify-content-center pt-5">
        <img src={img_logo} alt="" className="img_sizing"/>
      </Row>
      <Row className="justify-content-center">
        <h2 className="text-white">Madea</h2>
      </Row>

      <Row className="justify-content-center">
        <h2 className="text-white text-center sp_pad_home"> Guías y recursos de marca</h2>
      </Row>

      <Row className="my-3 justify-content-center">
        <Button color="primary" href="https://www.facebook.com/n12estudio" target="_blank" rel="noopener noreferrer"> Compartir </Button>

      </Row>
      <Row  className="mt-5 justify-content-center">
        <img src={arrow} alt="" className="mt-5 arrow_move"/>
      </Row>

    </Container>
  )
}

export default Home
